export default [
    {
        name: 'Fish',
        breed: 'tuxeda',
        species: 'cat',
        gender: 'male',
        age: 20,
        color: 'orange/white',
        weight: 17,
        location: 'fourside',
        routes: 'Pure breed, Trained for competitions.'
    },
    {
        name: 'Roger',
        breed: 'tabby',
        species: 'cat',
        gender: 'male',
        age: 20,
        color: 'gray',
        weight: 15,
        location: 'thread',
        routes: 'Super friendly.'
    },
    {
        name: 'Kitkat',
        breed: 'bombay',
        species: 'cat',
        gender: 'female',
        age: 9,
        color: 'black',
        weight: 9,
        location: 'thread',
        routes: 'Super friendly.'
    }
]