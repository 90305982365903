export default [
    {
        name: 'Sheeba',
        breed: 'collie',
        gender: 'female',
        age: 7,
        color: 'black/white',
        weight: 34,
        location: 'fourside',
        routes: 'Pure breed, Trained for competitions.'
    },
    {
        name: 'Hillary',
        breed: 'mut',
        gender: 'female',
        age: 17,
        color: 'orange/white',
        weight: 37,
        location: 'thread',
        routes: 'Super friendly.'
    },
    {
        name: 'Katie',
        breed: 'golden retriever',
        gender: 'female',
        age: 2,
        color: 'black',
        weight: 44,
        location: 'thread',
        routes: 'Super friendly.'
    }
]
//